import { css  } from '@emotion/core'
import { mq } from '../../cssInJs'

export default {
  top: css(mq({
    //height: [44, null, 80],
    height: [44, null, 56],
    //background: 'transparent',
    background: 'black',
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s'
  })),
  topScrolled: css(mq({
    //height: [44, null, 56],
    //background: 'black',
  })),
  logo: css(mq({
  })),
  logoImg: css(mq({
    display: 'block',
    height: [22, null, 36],
  })),
  // logoScrolled: css(mq({
    
  // })),
}