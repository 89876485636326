import React, { createRef } from 'react';
import env from '../../../env';
import { useForm } from '../../../form';
import { Button, ErrorMessage, usePost, useRouter } from '../../../components';
import { getContactModel } from './contactFormModel';
import { gtm } from '../../../lib/tracking';
import ReCAPTCHA from "react-google-recaptcha";

export function Contact(props) {
  const emailReceiver = props.emailReceiver;
  const fileAttachment = props.fileAttachment || 'false'; 
  const model = getContactModel({});
  const form = useForm(model, { usePlaceholder: true });
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const post = usePost();
  const recaptchaRef = createRef();
  const { history } = useRouter();

  // useEffect(() => {
  //   //google recaptcha
  //   const isScriptExist = document.getElementById('recaptcha-key'); 
  //   if (!isScriptExist){
  //     const script = document.createElement('script');
  //     script.src = 'https://www.google.com/recaptcha/api.js?render=6Lf21ywaAAAAAFaKWbgcn7QgoAHXuC57BUj8MXWV';
  //     script.id = 'recaptcha-key';
  //     // script.onload = function () {
  //     //   //if (callback) callback();
  //     // };
  //     document.body.appendChild(script);
  //   }

  // }, []);

  function onSubmit(e) {
    form.validateForm(e, () => {
      const recaptchaValue = recaptchaRef.current.getValue();
      const value = { ...form.getValue(), emailReceiver: emailReceiver, recaptchaValue : recaptchaValue };
      var files = form.getFiles();
      var formData = new FormData();
      formData.append('jsonData', JSON.stringify(value))
      files.forEach(file => {
        (file.files || []).forEach(uploadFile => {
          formData.append(file.name, uploadFile)
        })
      });
      //post.send(env.apiBase + "/api/contact/send", value);
      post.sendFormData(env.apiBase + "/api/contact/send", formData);
      setSendingStatus('pending')
    })
    console.log('submitting')
  }

  // if (status === 'done') {
  //   //navigate(`/task/view/${taskId}`)
  //   window.location.reload();
  // }

  if (post.done() && sendingStatus === 'pending') {
    var value = form.getValue();
    gtm.sendEvent('contactFormSubmitted', {contactEmail: value.email} );
    form.reset(model);
    setSendingStatus(null);
    
    setTimeout(() => {
      history.push("/thank-you ");
    }, 1500);
    
  }

  const render = (name) => form.renderControl(name, null);

  // function onChange(value) {
  //   console.log("Captcha value:", value);
  // }

  return (
    <form className="contactForm">
      <div className="form-row">
        <div className="col-md-4 mb-2">{render('name')}</div>
        <div className="col-md-4 mb-2">{render('phone')}</div>
        <div className="col-md-4 mb-2">{render('email')}</div>
      </div>
      
      <div className="form-row">
        <div className="col-md-12 mb-2">{render('enquiry')}</div>
      </div>

      { fileAttachment === 'true' &&
        <div className="form-row">
          <div className="col-md-12 mb-2"> {render('attachments')} </div>
        </div>
      }

      <div className="form-row">
        <div className="col-md-12 mb-2 recapthca-container">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LcS5iwaAAAAAABEue65K8YYnjqc0d7b9S9Bho9Q">
          </ReCAPTCHA>
        </div>
      </div>
      
      <div className="form-row">
        <div className="col-md-12 mb-2">
          <div className="actions">
            <Button onClick={onSubmit} status={post.status}>Send Message</Button>
            <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
            <ErrorMessage errors={post.errors} />
            {post.done() && <span style={{marginLeft: '1.5rem', color:'red', fontWeight: 'bold'}}>Successfully sent!</span>}
          </div>
        </div>
      </div>

    </form>
  );
}