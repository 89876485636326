const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"proxima-nova",serif',

  primaryColor: '#009345',
  fontColor: '#454949',
  bgColor: 'white',
  greenColor:'#009345',
  lightGreenColor:'#8bc53e',

  ...overloading
}

export default variables;